const state = {
    user_name : "",
    user_tel : "",
    user_headimg : "",
    nick_name:"",
    uid :0 ,
    last_login_time_str : '',
    last_login_ip : '',
    member_level:'',
    member_level_str:'',
    reg_time_str:'',
    vip_end_time_str: '',
    user_email : '',
    user_status:'',
    sex : '',
    birthday : '',
    location : '',
    cart_count : 0
};

const mutations = {
    SET_USERINFO: (state, info) => {
        state.uid = info.uid;  
        state.user_name = info.user_name;  
        state.user_tel = info.user_tel;  
        state.nick_name = info.nick_name;  
        state.user_headimg = info.user_headimg;  
        state.last_login_time_str = info.last_login_time_str; 
        state.last_login_ip = info.last_login_ip; 

        state.user_tel = info.user_tel; 
        state.nick_name = info.nick_name; 
        state.member_level = info.member_level; 
        state.member_level_str = info.member_level_str; 
        state.vip_end_time_str = info.vip_end_time_str; 
        state.reg_time_str = info.reg_time_str; 
        state.user_email = info.user_email; 
        state.sex = info.sex; 
        state.birthday = info.birthday; 
        state.location = info.location; 
        state.cart_count = info.cart_count; 
    },
    SET_CART_COUNT: (state, c) => {
        state.cart_count = c;  
    },
}

const actions = {
    set_userinfo({ commit }, info) {
        commit("SET_USERINFO", info);
    },
    add_cartcount({ commit }, info) {
        state.cart_count + info
        commit("SET_CART_COUNT", state.cart_count + info);
    }
}

export default {
    namespaced: true,
    state,
    actions,
    mutations,
};