import Vue from 'vue';
import Router from 'vue-router';
import HomeLayout from '@/views/layout/HomeLayout.vue';
import ProfileLayout from '@/views/layout/ProfileLayout.vue';

const originalPush = Router.prototype.push

Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

Vue.use(Router);

/**
* TIPS:
* meta: {
*   hidden: false,    // If `hidden:true` will not appear in the navigation bar or sidebar(default is false)
*   auth: [],         // It will control the page roles (you can set multiple roles)
*   icon: 'home',     // Icon will appear in the navigation bar or sidebar
*   hasMulSub: false, // It has multiple children
* }
*/
export default new Router({
  mode: 'history',
  //base: process.env.VUE_APP_DOMAIN,
  //scrollBehavior: () => ({ y: 0 }),
  routes: [
    {
      path: '/home',
      component: HomeLayout,
      props: { fromHome: true },
      children: [
        {
          path: '/',
          name: 'home',
          component: () => import('@/views/Home.vue'),
          meta: {},
        },
      ],
    },
    {
      path: '/profile',
      component: HomeLayout,
      
      children: [
        {
          path: '/',
          name: 'profile',
          component: ProfileLayout,
          children: [
            {
              path: '',
              redirect: () => {
                //console.log(Vue.vuetify )
                //console.log(this.$vuetify.breakpoint.mobile)
                return "/profile/myorder"
              },
            },
            {
              path: 'menu',
              name: 'menu',
              component: () => import('@/views/ProfileMenu.vue'),
              meta: {},
            },
            {
              path: 'basic',
              name: 'basic',
              component: () => import('@/views/Basic.vue'),
              meta: {},
            },
            {
              path: 'myorder',
              name: 'myorder',
              component: () => import('@/views/MyOrder.vue'),
              meta: {},
            },
            {
              path: 'create',
              name: 'create',
              component: () => import('@/views/NewTopic.vue'),
              meta: {},
            },
            {
              path: 'mytopic',
              name: 'mytopic',
              component: () => import('@/views/MyTopic.vue'),
              meta: {},
            },
            {
              path: 'orderdetail',
              name: 'orderdetail',
              component: () => import('@/views/OrderDetail.vue'),
              meta: {},
                
            },
            {
              path: 'favorite',
              name: 'favorite',
              component: () => import('@/views/Favorite.vue'),
              meta: {},
            },
            {
              path: 'coupon',
              name: 'coupon',
              component: () => import('@/views/MyCoupon.vue'),
              meta: {},
            },
            {
              path: 'address',
              name: 'address',
              component: () => import('@/views/Address.vue'),
              meta: {},
            },
            {
              path: 'withdraw',
              name: 'withdraw',
              component: () => import('@/views/WithDraw.vue'),
              meta: {},
            },
            {
              path: 'member_level',
              name: 'member_level',
              component: () => import('@/views/MemberLevel.vue'),
              meta: {},
            },
            {
              path: 'security',
              name: 'security',
              component: () => import('@/views/Security.vue'),
              meta: {},
            },
            {
              path: 'balance',
              name: 'balance',
              component: () => import('@/views/Balance.vue'),
              meta: {},
            },
          ],
        },
      ],
    },
    {
      path: '/itemlist',
      component: HomeLayout,
      children: [
        {
          path: '/',
          name: 'itemlist',
          component: () => import('@/views/ItemList.vue'),
          meta: {},
        },
      ],
    },
    {
      path: '/itemdetail',
      component: HomeLayout,
      children: [
        {
          path: '/',
          name: 'itemdetail',
          component: () => import('@/views/ItemDetail.vue'),
          meta: {},
        },
      ],
    },
    {
      path: '/cart',
      component: HomeLayout,
      children: [
        {
          path: '/',
          name: 'cart',
          component: () => import('@/views/Cart.vue'),
          meta: {},
        },
      ],
    },
    {
      path: '/order',
      component: HomeLayout,
      children: [
        {
          path: '/',
          name: 'order',
          component: () => import('@/views/Settlement.vue'),
          meta: {},
        },
      ],
    },
    
    {
      path: '/brand',
      component: HomeLayout,
      children: [
        {
          path: '/',
          name: 'brand',
          component: () => import('@/views/Brand.vue'),
          meta: {},
        },
      ],
    },
    {
      path: '/branddetail',
      component: HomeLayout,
      children: [
        {
          path: '/',
          name: 'branddetail',
          component: () => import('@/views/BrandDetail.vue'),
          meta: {},
        },
      ],
    },
    {
      path: '/topic',
      component: HomeLayout,
      children: [
        {
          path: '/',
          name: 'topic',
          component: () => import('@/views/Topic.vue'),
          meta: {},
        },
      ],
    },
    {
      path: '/kol',
      component: HomeLayout,
      children: [
        {
          path: '/',
          name: 'kol',
          component: () => import('@/views/KolTopic.vue'),
          meta: {},
        },
      ],
    },
    {
      path: '/topicdetail',
      component: HomeLayout,
      children: [
        {
          path: '/',
          name: 'topicdetail',
          component: () => import('@/views/TopicDetail.vue'),
          meta: {},
        },
      ],
    },
    {
      path: '/payreturn',
      component: HomeLayout,
      children: [
        {
          path: '/',
          name: 'payreturn',
          component: () => import('@/views/PayReturn.vue'),
          meta: {},
        },
      ],
    },
    {
      path: '/bindaccount',
      component: HomeLayout,
      children: [
        {
          path: '/',
          name: 'bindaccount',
          component: () => import('@/views/BindAccount.vue'),
          meta: {},
        },
      ],
    },
    {
      path: '/help',
      component: HomeLayout,
      children: [
        {
          path: '/',
          name: 'help',
          component: () => import('@/views/Help.vue'),
          meta: {},
        },
      ],
    },
    {
      path: '/',
      redirect: { name: 'home' },
      meta: {
        hidden: true,
      },
    },

    {
      path: '/404',
      meta: {
        hidden: true,
      },
      component: () => import('@/views/error-pages/App404.vue'),
    },
    {
      path: '*',
      redirect: '/404',
      meta: {
        hidden: true,
      },
    },
  ],
});
