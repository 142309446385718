
import request from './request';


export default new function API() {

  
  this.getsetting = () => request.get({action:"getsetting"});


}();
