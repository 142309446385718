import '@mdi/font/css/materialdesignicons.css'
import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';


Vue.use(Vuetify);

export default new Vuetify({
    ripple: false,
    icons: {
        iconfont: 'mdi', // 默认值 - 仅用于展示目的
    },
    breakpoint: {
      mobileBreakpoint: 'sm' // 这个值等于960
    },
    theme: {
        themes: {
          light: {
            primary: '#000000',
          }
        },
    },
});
