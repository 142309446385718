<template>
    <div class="content mx-0 mt-md-5 mb-16 px-0 px-sm-16  mx-sm-12">
        <div>
            <v-img class="prfilebanner  align-end" :aspect-ratio="$vuetify.breakpoint.mobile?1450/272:1610/242"
                :src="domain + profiles_banner"
            >
            <div class="top-title text-subtitle-1  pl-4 text-left mb-0" >您好,<br />{{nick_name}}</div>
            
            </v-img>
        </div>
        <div class="">
            <v-row>
                <v-col class="d-flex" cols="0" md="2" >
                    <v-card
                        class="mx-auto d-none d-md-flex"
                        max-width="400"
                        till flat
                        
                    >
                        <v-list flat>
                        <v-list-item-group v-model="activeItem" color="indigo" >
                            <v-list-item to="/profile/myorder">
                                <v-list-item-content>
                                    <v-list-item-title>我的订单</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>

                            <v-list-item to="/profile/favorite">
                                <v-list-item-content>
                                    <v-list-item-title >愿望单</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>

                            <v-list-item to="/profile/coupon">
                                <v-list-item-content>
                                    <v-list-item-title >我的优惠券</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>

                            <v-divider></v-divider>

                            <v-list-item to="/profile/create">
                                <v-list-item-content>
                                    <v-list-item-title >上传推文</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>

                            <v-list-item to="/profile/mytopic">
                                <v-list-item-content>
                                    <v-list-item-title >我的推文</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>

                            <v-list-item to="/profile/balance">
                                <v-list-item-content>
                                    <v-list-item-title >我的金库</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>

                            <v-divider></v-divider>


                            <v-list-item  to="/profile/member_level">
                                <v-list-item-content>
                                    <v-list-item-title >VIP会员</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item to="/profile/basic">
                                <v-list-item-content>
                                    <v-list-item-title >个人信息</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>

                            <v-list-item to="/profile/address">
                                <v-list-item-content>
                                    <v-list-item-title >地址管理</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>

                            <v-list-item  to="/profile/security">
                                <v-list-item-content>
                                    <v-list-item-title >账号安全</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>

                            <v-list-item @click="logout">
                                <v-list-item-content>
                                    <v-list-item-title >退出登录</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>

                        </v-list-item-group>
                        </v-list>
                    </v-card>
                </v-col>
                <v-col class="" cols="12" md="10">
                    <router-view></router-view>
                </v-col>
            </v-row>
           
        </div>
    </div>
</template>

<script>

import { mapState } from 'vuex'
import userApi from '@/api/user' 
export default {
    data(){
        return{
            activeItem : 0
        }
    },
    
    computed: {
        ...mapState({
        domain: state => state.app.imgdomain,
        nick_name: state => state.user.nick_name,
        profiles_banner: state => state.app.profiles_banner,
        }),
    },
    methods: {
      logout() {
        userApi.logout().then(()=>{
            this.$store.dispatch("user/set_userinfo",{user_name:false,cart_count:0})
            this.$router.push("/")
        })
      }
    },
}
</script>

<style scoped>
.content {
  
  position: relative;
}

</style>
<style lang="sass">
  @import '~vuetify/src/styles/styles.sass'

  @media #{map-get($display-breakpoints, 'md-and-up')}
    .prfilebanner .v-image__image--cover
      background-position : center

  @media #{map-get($display-breakpoints, 'sm-and-down')}
    .prfilebanner .v-image__image--cover
      background-position : 66% !important
  
  .v-card.on-hover.theme--dark
    background-color: rgba(#FFF, 0.8)
    >.v-card__text
      color: #000000

</style>