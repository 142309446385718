<template>
  
    <v-dialog
      v-model="dialog"
      content-class="winborder"
      max-width="600px"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          icon small class="px-1 pb-1 ml-3"
          v-bind="attrs"
          v-on="on"
        >
          
          <img src="../assets/login_icon.png" class="mb-1" width=18 height=20 />
        </v-btn>
       
      </template>

      <v-card v-if="showresetpwd">

        <v-btn  icon small class="favorite-icon mt-6 mr-0" fab absolute top right @click.stop="hideDialog" >
          <v-icon size=20>mdi-close</v-icon>
        </v-btn>

        <v-card-title>
          <span class="text-h6">重置密码</span>
        </v-card-title>
        <v-card-text>
          <v-container >
            <v-row>
              <v-col cols="12" class="px-3 py-2">
                <v-text-field
                  label="手机号"
                  required
                  v-model="mobile"
                  class="mt-0 pt-0"
                ></v-text-field>
              </v-col>
              <v-col cols="6" class="px-3 py-0">
                <v-text-field
                  label="手机验证码"
                  v-model="reset_code"
                  
                  required
                  class="mt-0 pt-0"
                ></v-text-field>
              </v-col>
              <v-col cols="6" class="px-3 py-0">
                <v-btn  dark outlined  tile class="login-btn" @click="sendcode()">发送验证码</v-btn>
              </v-col>
              <v-col cols="12" class="px-3 py-0">
                <v-text-field
                  label="密码"
                  v-model="reset_password"
                  type="password"
                  required
                  class="mt-0 pt-0"
                ></v-text-field>
              </v-col>
              <v-col cols="12"  class="px-3 pt-1">
                <v-btn  dark outlined  tile class="login-btn" @click="doreset()">确认</v-btn>
              </v-col>
              <v-col cols="12"  class="px-3 pt-0">
                <v-btn  light outlined  tile class="register-btn" @click="showresetpwd=false">返回</v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>

      <v-card v-if="!showresetpwd">

        <v-btn  icon small class="favorite-icon mt-6 mr-0" fab absolute top right @click.stop="hideDialog" >
          <v-icon size=20>mdi-close</v-icon>
        </v-btn>

        <v-card-title class="pl-9">
          <span class="text-h6">登录或创建账户</span>
        </v-card-title>
        <v-card-text>
          <v-container v-if="!showRegCode">
            <v-row>
              <v-col cols="12" class="px-3 py-2">
                <v-text-field
                  label="手机号"
                  required
                  v-model="mobile"
                  class="mt-0 pt-0"
                ></v-text-field>
              </v-col>
              <v-col cols="12" class="px-3 py-0">
                <v-text-field
                  label="密码"
                  v-model="password"
                  type="password"
                  required
                  class="mt-0 pt-0"
                ></v-text-field>
              </v-col>

               <v-col cols="12" class="px-3 py-0 my-0">
                <small class="pointer"  @click="showresetpwd=true">找回密码</small>
              </v-col>

              <v-col cols="12"  class="px-3 pt-1">
                <v-btn  dark outlined  tile class="login-btn" @click="login">登录</v-btn>
              </v-col>
              <v-col cols="12"  class="px-3 pt-0">
                <v-btn  light outlined  tile class="register-btn" @click="goShowRegCode">创建账户</v-btn>
              </v-col>

              <v-col cols="12"  class="pa-3">

                <div class="d-flex flex-row justify-space-between">
                  
                  <div class="login-line"></div>
                 
                  <div class="text-center">第三方登录 </div>
                  
                  <div class="login-line"></div>
                </div>
        
                <div class="d-flex justify-space-around mt-4">
                  <div class="d-flex align-center plainbtn" @click="login3p('WCHAT')">
                    <img src="../assets/logo_weixin.png" width="40px" alt="微信" />
                   
                  </div>
                  <!--<div class="d-flex align-center plainbtn"  @click="login3p('QQLOGIN')">
                    <svg class="font-icon othericon" aria-hidden="true">
                      <use xlink:href="#icon-qqdenglu"></use>
                    </svg>
                   
                  </div>-->
                  <div class="d-flex align-center plainbtn"  @click="login3p('SINALOGIN')">
                    <img src="../assets/logo_weibo.png" width="40px" alt="微博" />
                   
                  </div>
                </div>
              </v-col>
            </v-row>
          </v-container>

          <v-container v-if="showRegCode">
            <v-row>
              <v-col cols="12" class="pa-2">
                请输入手机号码({{mobile}})收到的来自【VLWANG】的验证码
              </v-col>
              <v-col cols="12" class="pa-2">
                <v-text-field
                  label="验证码"
                  required
                  v-model="code"
                  class="mt-0 pt-0"
                ></v-text-field>
              </v-col>

              <v-col cols="12"  class="pa-1">
                <v-btn  dark outlined class="login-btn" tile  @click="register">继续</v-btn>
              </v-col>

              <v-col cols="12"  class="pa-1">
                <v-btn  light outlined class="register-btn" tile @click="showRegCode=false">返回</v-btn>
              </v-col>
              
            </v-row>
          </v-container>
          
        </v-card-text>
      </v-card>
    </v-dialog>

</template>

<script>

import userApi from '@/api/user'
import { mapState } from 'vuex'
export default {
  name: 'LoginWin',

  data: () => ({
      dialog: false,
      showresetpwd:false,
      mobile:"",
      password:"",
      code:"",
      showRegCode:false,
      userinfo : null,
      reset_code:"",
      reset_password:"",

  }),
  computed: {
    ...mapState({
      domain: state => state.app.domain,
      showlogin: state => state.app.showlogin,
    }),
  },
  watch : {
    showlogin(v) 
    {
      this.dialog = v
    },
    dialog(v) 
    {
      this.$store.dispatch("app/showlogin",v)
    }
  },

  methods: {
    showDialog(){
      this.$store.dispatch("app/showlogin",true)
    },
    hideDialog(){
      this.$store.dispatch("app/showlogin", false)
    },
    login() {
      let params = {username:this.mobile,password:this.password}
      userApi.login(params).then(data => {

        if (data.message=='success'){
          //this.dialog = false
          this.$store.dispatch("app/showlogin",false)

          this.$util.setToken(data.data.token)

          
          this.$emit("userinit")
          

          /*this.userinfo = {}
          this.userinfo.uid = data.data.uid
          this.userinfo.user_tel = data.data.user_tel
          this.userinfo.user_headimg = data.data.user_headimg
          this.userinfo.user_name = data.data.user_name
          this.userinfo.nick_name = data.data.nick_name
          this.userinfo.cart_count = data.data.cart_count


          this.$store.dispatch("user/set_userinfo",this.userinfo)*/

          if (data.data.uid>0) {
            this.$router.push({
              path: this.$route.path,
              query: Object.assign({}, this.$route.query, {source_uid: data.data.uid})
            })

            /*let suid = this.$util.getSourceId()
            if (suid && suid==this.userinfo.uid) {
              this.$util.clearSourceId()

            }*/
          }

        } else {
          this.$message.warning('登录失败，请检查账号密码')
        }

        /*if (data.data.code==1){
          this.dialog = false

          this.$util.setToken(data.data.token)
          this.userinfo = {}
          this.userinfo.uid = data.data.uid
          this.userinfo.user_tel = data.data.user_tel
          this.userinfo.user_headimg = data.data.user_headimg
          this.userinfo.user_name = data.data.user_name
          this.userinfo.nick_name = data.data.nick_name
          this.$store.dispatch("user/set_userinfo",this.userinfo)
        } else {
          this.$message.warning('登录失败')
        }*/
        
        
      })
    
      
      
    },
    sendcode()
    {
      if (this.mobile.length != 11) {
        this.$message.warning('请输入11位手机号码')
        
        return
      }
      userApi.mobilecode({mobile:this.mobile}).then((data) => {
        if (data.data.code==0){
          this.$message.success("请输入来自【VLWANG】的验证码！")
        } else {
          this.$message.warning(""+data.data.message)
          console.log(data)
        }
        
        
      })
    },
    doreset() {
      console.log(2)
      if (this.reset_code.length != 4) {
        this.$message.warning('请输入4位手机验证码')
        return
      }
      let params = {account:this.mobile, password:this.password, code:this.reset_code}
      
      userApi.resetpwd(params).then((data) => {

        if (data.code==0){
          this.$message.success("重置密码成功!")
          this.showresetpwd = false
          
        } else {
          
          this.$message.warning("重置密码失败:"+data.data)
          
        }
        //console.log(data)
        
        
      })
    },
    register() {
      
      if (this.code.length != 4) {
        this.$message.warning('请输入4位手机验证码')
        return
      }
      let params = {mobile:this.mobile, password:this.password, mobile_code:this.code}
      let source_uid = this.$util.getSourceId()
      if (source_uid!=null && source_uid!=0) {
        params.source_uid = source_uid
      }
      userApi.register(params).then((data) => {

        if (data.data.code==1){
          this.$message.success("注册成功，自动登录...")
          
          setTimeout(
            this.login
          , 1000)
        } else {
          
          this.$message.warning("注册失败:"+data.data.message)
          
        }
        console.log(data)
        
        
      })
      
    },
    goShowRegCode() {

      if (this.mobile.length != 11) {
        this.$message.warning('请输入11位手机号码')
        console.log(this.mobile)
        return
      }
      userApi.mobilecode({mobile:this.mobile}).then((data) => {
        if (data.data.code==0){
          console.log(data)
          this.showRegCode = true
        } else {
          this.$message.warning("发送手机验证码失败:"+data.data.message)
          console.log(data)
        }
        
        
      })
      //this.showRegCode = true
    },
    login3p(type) {
      this.$util.setSourceUrl(location.href)
      location.href= this.domain + 'api.php?action=login3p&type='+type
    }
  }
}
</script>

<style scoped>
.login-btn {
  background-color: black;
  color:#ffffff;
  
  width:100%;
}
.register-btn {
  background-color:#ffffff;
  color:black;
  
  width:100%;
}
.othericon{
  font-size: 42px;

}

</style>
<style lang="sass">
  @import '~vuetify/src/styles/styles.sass'

  .winborder 
    border:12px solid #000000
  @media #{map-get($display-breakpoints, 'md-and-up')}
    .login-line 
      border-top:1px solid #cccccc
      width:200px
      margin-top:10px
  
  @media #{map-get($display-breakpoints, 'sm-and-down')}
    .login-line 
      border-top:1px solid #cccccc
      width:90px
      margin-top:10px
  
    
</style>