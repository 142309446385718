const state = {
    pre_order_info : []
};

const mutations = {
    SET_PRE_ORDER_INFO: (state, info) => {
        state.pre_order_info = info
    }
}

const actions = {
    set_pre_order_info({ commit }, info) {
        commit("SET_PRE_ORDER_INFO", info);
    }
}

export default {
    namespaced: true,
    state,
    actions,
    mutations,
};