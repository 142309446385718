<template>
  <div>
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'App',
  created() {
    this.$store.dispatch("app/domain", process.env.VUE_APP_DOMAIN)
    this.$store.dispatch("app/apidomain", process.env.VUE_APP_API_DOMAIN)
    this.$store.dispatch("app/imgdomain", process.env.VUE_APP_IMGDOMAIN)
  }
};
</script>
