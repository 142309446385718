<template>
  <v-app id="inspire">
    <v-app-bar  class="smfixed text-center mx-0 px-sm-16 mx-sm-12" :class="{overlaybg1:overlay}" flat height=80>

      <v-app-bar-nav-icon @click.stop="drawer = !drawer" v-if="$vuetify.breakpoint.mobile" ></v-app-bar-nav-icon>

      <v-toolbar-title>
        <v-img
          @click="$router.push('/')"
          class="vlogo"
          max-height="44"
          max-width="251"
          src="../../assets/logo2.png"
        ></v-img>
      </v-toolbar-title>
      <v-spacer></v-spacer>

      <div class="d-flex flex-column align-end" @mouseenter="showSearchInput=true" @mouseleave="showSearchInput=false">
        <div class="d-flex pt-3 pb-0">
          <v-text-field
            v-if="!$vuetify.breakpoint.mobile && showSearchInput"
            dense
            hide-details
            v-model="keyword"
            label=""
            clearable
          ></v-text-field>

          <v-btn icon small width=19 class="ml-1" @click="homesearch" v-if="!$vuetify.breakpoint.mobile">
            <img src="../../assets/search_icon.png" class="mb-1" width=20 />
          </v-btn>

          <v-btn icon small width=19 class="ml-5" to="/profile/favorite">
            <img src="../../assets/favorite_icon.png" class="mb-1" width=20 />
          </v-btn>

          
          <v-badge :content="cart_count" class="ml-4" color="red" overlap avatar dot :value="cart_count">
          <v-btn icon small class=""  to="/cart">
            <!--<svg class="font-icon header-icon" aria-hidden="true">
              <use xlink:href="#icon-shop-cart-7"></use>
            </svg>-->
            <img src="../../assets/cart_icon.png" class="mb-2" width=18 height=20 />
          </v-btn>
          </v-badge>

          <LoginWin v-if="!username" @userinit="userinit" />
          <v-avatar class="ml-4  pointer" color="black" size="22" v-if="username" @click="goProfile">
            <span class="white--text text-subtitle-2 " v-if="user_headimg==''">{{username.substring(0,1)}}</span>
            <v-img  :src="domain+user_headimg" v-if="user_headimg!=''"></v-img>
          </v-avatar>
          <!--<v-chip outlined pill small v-if="username" @click="goProfile">{{username.substring(0,1)}}</v-chip>-->
        
        </div>
        <div class="text-right d-none d-md-flex mt-2">
          <v-btn  plain class="px-4 header-btn" height=26 to="/itemlist?lastest=1">
            最近上新
          </v-btn>

          <v-menu open-on-hover tile left offset-y  content-class="top-menu" v-for="cate in menulist" :key="cate.category_id">
            <template v-slot:activator="{ on, attrs }" >
              <v-btn plain  class="px-4 header-btn" height=26 v-bind="attrs"  v-on="on" @click="$router.push('/itemlist?cate='+cate.category_id);">
                {{cate.category_name}}
              </v-btn>
            </template>
            <div class="px-16 py-0 memu-over">
                  <v-row no-gutters class=" px-4 mx-12 list-group py-4" >
                    <v-col cols=6 >
                      <v-img :src="domain + cate.category_pic" ></v-img>
                    </v-col>
                    <v-col cols=6 class="d-flex align-content-start flex-wrap  pl-12 pr-2 py-4">
                      <div class="text-left cate-item pa-2 sale"  @click="$router.push('/itemlist?sale=1&cate='+cate.category_id)"> SALE </div>
                      <div class="text-left cate-item pa-2" v-for="childcate in cate.child_list" :key="childcate.category_id" flat tile @click="$router.push('/itemlist?cate='+childcate.category_id)">
                        {{childcate.category_name}}
                      </div>
                      <div class="text-left cate-item pa-2 allmale" @click="$router.push('/itemlist?cate='+cate.category_id);"> 全部 </div>
                    </v-col>
                  </v-row>
              </div>
          </v-menu>

          <!--<v-menu open-on-hover tile left  offset-y  content-class="top-menu" v-model="isOpened2">
            <template v-slot:activator="{ on, attrs }" >
              <v-btn plain  class="px-6 header-btn " height=26 v-bind="attrs"  v-on="on" @click="$router.push('/itemlist?cate='+femaleid);">
                女士
              </v-btn>
            </template>
            <div class="  px-16  py-0 memu-over" >         
                  <v-row no-gutters class=" px-4 mx-12 list-group py-4">
                    <v-col cols=6 >
                      <v-img :src="femalepic" ></v-img>
                    </v-col>
                    <v-col cols=6 class="d-flex align-content-start flex-wrap  pl-12 pr-2 py-4">
                      <div class="text-left cate-item pa-2 sale"  @click="$router.push('/itemlist?female=1&sale=1')"> SALE </div>
                      <div class="text-left cate-item pa-2" v-for="item in femalelist" :key="item.category_id" flat tile @click="$router.push('/itemlist?cate='+item.category_id)">
                        {{item.category_name}}
                      </div>
                      <div class="text-left cate-item pa-2 allmale"  @click="$router.push('/itemlist?cate='+femaleid);"> 全部女装 </div>
                    </v-col>
                  </v-row>
              
            </div>
          </v-menu>-->

          <v-btn plain  class="px-4 header-btn sale" height=26 to="/itemlist?sale=1">
            SALES
          </v-btn>

          <v-btn plain  class="px-4 header-btn" height=26 to="/brand">
            品牌
          </v-btn>
          <v-btn plain  class="pl-4 pr-1 header-btn" height=26 to="/topic">
            潮流专题 
          </v-btn>
          
        </div>
      </div>
    

      
      
    </v-app-bar>

    <v-navigation-drawer
      v-model="drawer"
      fixed
      temporary
    >
      <v-btn icon small class="mt-10 ml-7">
        
      </v-btn>

      <v-text-field
          class="px-2 mt-5"
          label="搜索商品"
          v-model="keyword"
          prepend-inner-icon="mdi-magnify"
          clearable
          type="search"
          @search="homesearch"
        ></v-text-field>

      <v-list class="mx-2">
        <v-subheader class="font-weight-bold text-subtitle-1 red--text text--darken-4" @click="$router.push('/itemlist?sale=1')">SALE</v-subheader>
        <v-subheader class="font-weight-bold text-subtitle-1 black--text text--darken-4 list-group" @click="$router.push('/itemlist?lastest=1')">最近上新</v-subheader>
        <v-list-group
          v-for="menu in menulist"
          :key="menu.category_id"
          v-model="menu.active"
          
          class="list-group"
        >
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title  class="font-weight-bold black--text text--darken-4" v-text="menu.category_name"></v-list-item-title>
            </v-list-item-content>
          </template>

           <v-list-item>
            <v-list-item-content>
              <v-list-item-title v-text="'所有'+menu.category_name" @click="$router.push('/itemlist?cate='+menu.category_id)"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item
            v-for="child in menu.child_list"
            :key="child.category_id"
            
          >
            <v-list-item-content>
              <v-list-item-title v-text="child.category_name" @click="$router.push('/itemlist?cate='+child.category_id)"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
         
        </v-list-group>
        <v-subheader class="font-weight-bold text-subtitle-1 black--text text--darken-4 list-group" @click="$router.push('/brand')">品牌</v-subheader>
        <v-subheader class="font-weight-bold text-subtitle-1 black--text text--darken-4 list-group" @click="$router.push('/topic')">潮流专题</v-subheader>
      </v-list>



    </v-navigation-drawer>

    <v-main absolute>
        <v-container fluid class="mainbody pb-8">
            <router-view></router-view>
        </v-container>

        <div class="black  mt-12 pt-8 pt-md-0" >
         <v-row class="white--text mt-md-2 pa-10">
          <v-col  cols=12 md=6>
            <div>
              <v-img
                  max-height="35"
                  max-width="181"
                  src="../../assets/logo-white2.png"
                ></v-img>
            </div>
            <div class="d-flex mt-8">
              <div class="pt-0 text-subtitle-1 font-weight-black pl-3">关注我们</div>
              <v-menu top offset-y nudge-top=-10 v-if="!$vuetify.breakpoint.mobile" open-on-hover>
              <template v-slot:activator="{ on, attrs }">
                <img src="../../assets/weixin-icon-2.png" class="ml-6" width="32px" height="27px" v-bind="attrs" v-on="on" />
                
              </template>
              <v-img src="../../assets/wx-gzh-qrcode.png"></v-img>
            </v-menu>
            <v-menu top offset-y nudge-top=-10 v-if="!$vuetify.breakpoint.mobile" open-on-hover>
              <template v-slot:activator="{ on, attrs }">
              <img src="../../assets/weibo-icon-2.png" class="ml-6" width="32px"  height="27px" v-bind="attrs" v-on="on" />
              </template>
              <v-img src="../../assets/weibo-qrcode.png"></v-img>
            </v-menu>
              
            </div>
          </v-col>
          <v-col class="d-flex flex-row pl-6"  cols=12 md=6> 
            <v-row>
              <v-col cols=12 md=4>
                <div class="text-subtitle-1 font-weight-black">购物指南</div>
                <div class="grey--text pointer mt-2" @click="$router.push('/help?id=22')">如何购物</div>
              </v-col>
              <v-col  cols=12 md=4>
                <div class="text-subtitle-1 font-weight-black">配送&售后</div>
                <div class=" mt-2">
                  <div class="grey--text pointer" @click="$router.push('/help?id=23')">配送说明</div>
                  <div class="grey--text pointer" @click="$router.push('/help?id=24')">退货政策</div>
                  <div class="grey--text pointer" @click="$router.push('/help?id=25')">退款说明</div>
                </div>
              </v-col>
              <v-col cols=12 md=4>
                <div class="text-subtitle-1 font-weight-black">关于我们</div>
                <div class=" mt-2">
                  <div class="grey--text pointer" @click="$router.push('/help?id=26')">关于VL.WANG</div>
                  <div class="grey--text pointer" @click="$router.push('/help?id=27')">合作联系</div>
                  <div class="grey--text pointer" @click="$router.push('/help?id=28#a')">联系我们</div>
                  <div class="grey--text pointer" @click="$router.push('/help?id=29#b')">使用条款和条件</div>
                </div>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row class="grey--text text-left pl-12">
          <v-col cols="12" md="6" class="pa-1">
              网络文化经营许可证：浙网文[2017]0175-035号
          </v-col>
          <v-col cols="12" md="6" class="pa-1">
              增值电信业务经营许可证：浙B2-20093185-3
          </v-col>
          <v-col cols="12" md="6" class="pa-1">
              出版物经营许可证：新出发浙杭经零字第00166号
          </v-col>
          <v-col cols="12" md="6" class="pa-1">
              食品经营许可证：LN13396360112412
          </v-col>
          <v-col cols="12" md="6" class="pa-1">
              公网安备 33010002060022号
          </v-col>
          <v-col cols="12" md="6" class="pa-1">
              VL.WANG公司版权所有©2016-2026
          </v-col>
        </v-row>
      </div>`
        
    </v-main>

    
    <v-overlay :value="overlay" z-index="4"></v-overlay>
  </v-app>
</template>

<script>

  import { mapState } from 'vuex'
  import goodsApi from '@/api/goods'
  import userApi from '@/api/user'
  import appApi from '@/api/app'
  import { getToken } from '@/utils/util'
  import LoginWin from '@/components/LoginWin';


  export default {
    components: {
      LoginWin
    },
    data: () => ({
      shareUrl:"",
      keyword:"",
      isOpened1:false,
      isOpened2:false,
      showSearchInput:false,
      maleid : 0,
      femaleid : 0,
      overlay : false,
      drawer: false ,
      group:false,
      menulist : [],
      malelist : [],
      malepic : "",
      femalelist : [],
      femalepic : "",
      searchType : "goods",
      searchitems : [{value:"goods",label:"商品"},{value:"topic",label:"专题"}]
    }),
    props:["fromHome"],
    methods: {
      goProfile() {
        if (this.$vuetify.breakpoint.mobile) {
          this.$router.push('/profile/menu')
        } else {
          this.$router.push('/profile/myorder')
        }
        
      },
      test(){
        console.log("test")
      },
      
      homesearch() {
        if (this.keyword=="") {
          return
        }
        this.$router.push('/itemlist?kw='+this.keyword)
      },
      setSuid() {
        let source_uid = this.$route.query.source_uid
        source_uid = parseInt(source_uid)
        if (source_uid>0 && source_uid!=this.uid) {
          this.$util.setSourceId(source_uid)
        }
      },
      userinit() {
        let token = getToken()
        if (token != null){
          userApi.userinfo().then((data) => {

            if (data.data.uid)
            {
              let userinfo = {} 
              userinfo.uid = data.data.uid
              userinfo.user_name = data.data.user_name
              userinfo.nick_name = data.data.nick_name
              userinfo.user_tel = data.data.user_tel
              userinfo.user_headimg = data.data.user_headimg

              userinfo.last_login_time_str = data.data.last_login_time_str
              userinfo.last_login_ip = data.data.last_login_ip
              userinfo.member_level = data.data.member?data.data.member.member_level:0
              userinfo.member_level_str = data.data.member?data.data.member.member_level_str:""
              userinfo.vip_end_time_str = data.data.member.vip_end_time_str?data.data.member.vip_end_time_str:""
              userinfo.reg_time_str = data.data.reg_time_str
              userinfo.user_email = data.data.user_email
              userinfo.user_status = data.data.user_status
              userinfo.sex = data.data.sex
              userinfo.birthday = data.data.birthday
              userinfo.location = data.data.location
              userinfo.cart_count = data.data.cart_count

              //this.$store.commit("SET_USERINFO",this.userinfo)
              this.$store.dispatch("user/set_userinfo",userinfo)
              let suid = this.$util.getSourceId()
              if (suid && suid==userinfo.uid) {
                this.$util.clearSourceId()

              } else {
                this.setSuid()
              }

            }
            else {
  
              this.setSuid()
            }
            
          })
        }
        else {
          this.setSuid()
        }
      }
    },
    watch:{
      isOpened1: function(v){
        this.overlay = v
      },
      isOpened2: function(v){
        this.overlay = v
      } 
    },
    computed: {
      ...mapState({
        domain: state => state.app.imgdomain,
        user_headimg: state => state.user.user_headimg,
        username: state => state.user.nick_name,
        uid: state => state.user.uid,
        cart_count: state => state.user.cart_count,
      }),
    },
    created() {

      this.userinit()

      goodsApi.catelist().then((data)=>{

        this.menulist = data.data
        /*data.data.forEach(item => {
          if (item.category_name=='男装') {
            this.malelist = item.child_list
            this.malepic = this.domain +item.category_pic
            this.maleid = item.category_id
            this.menulist.push(item)
          } else if (item.category_name=='女装') {
            this.femalelist = item.child_list
            this.femalepic = this.domain+item.category_pic
            this.femaleid = item.category_id
            this.menulist.push(item)
          }
          
        });*/
      })

      appApi.getsetting().then(res => {
        let advlist = res.data
        let advmap = {"HOME_BANNER" : [], "HOME_CATE" : [], "HOME_WEEKPOINT" : [], "HOME_TOPIC":{},"GOODS_BANNER":{},"BRAND-BANNER":{},"PROFILES_BANNER":""}
        advlist.forEach(item => {
          
          let key = item.ap_keyword.split("-")
          
          if (key[0]=='HOME' && key[1]=='BANNER') {
            advmap.HOME_BANNER.push(item)
          } else if (key[0]=='HOME' && key[1]=='CATE') {
            advmap.HOME_CATE.push(item)
          } else if (key[0]=='HOME' && key[1]=='WEEKPOINT') {
            advmap.HOME_WEEKPOINT.push(item)
          } else if (key[0]=='HOME' && key[1]=='TOPIC') {
            
            this.$store.dispatch("app/topic_image", item.adv_image)
          } else if (key[0]=='GOODS' && key[1]=='BANNER') {
            this.$store.dispatch("app/goods_banner", item.adv_image)
          } else if (key[0]=='BRAND' && key[1]=='BANNER') {
            this.$store.dispatch("app/brand_banner", item)
          } else if (key[0]=='PROFILES' && key[1]=='BANNER') {
            this.$store.dispatch("app/profiles_banner", item.adv_image)
          } 
        })
        //console.log(1,advmap)
        this.$store.dispatch("app/home_banner", advmap.HOME_BANNER)
        this.$store.dispatch("app/home_cate", advmap.HOME_CATE)
        this.$store.dispatch("app/home_point", advmap.HOME_WEEKPOINT)
        
      })
      
      this.$util.weixinShare(window.location.href,document.title,this.domain + 'logo_icon_big.png')
      
      
      
      
    },
    mounted() {
      
    }
  }
</script>

<style scoped>

.app-bottom {
  width:100%;
}

.container {
  padding:0px;
  
}
.theme--light.v-app-bar.v-toolbar.v-sheet {
  background-color:transparent 
}
.theme--light.v-app-bar.v-toolbar.v-sheet.v-app-bar--is-scrolled {
  background-color: #ffffff;
}
.overlaybg {
  background-color: #ffffff !important;
}
.list-group{
  margin-top:1px;
  border-top:1px solid black;
}
.v-overlay {
  top:80px;
}
.v-card--reveal {
  top: 80px;
  left:0px;
  
  position: absolute;
  width: 100%;
  height:380px;
}
.memu-over {
  background-color: #ffffff;
  width: 100%;
  height:380px;
  margin-top:2px;
}
.top-menu{
  width:100%;
  max-width:100%;
  left:0px !important;
  
  box-shadow: none;

}
>>> .v-toolbar__content{
  color:#000000;
  position: static ;
  padding:0px 16px;
  border-bottom:1px solid black;
}

.smfixed  {
    position: fixed !important;
}
.mainbody {
  position: relative;
  top:80px;
  bottom: 100px;
}
.cate-item {
  cursor: pointer;
  width:33%;
  
}
.sale{
  color:#820000;
}
.allmale {
  text-decoration:underline;
}
.othericon{
  font-size: 28px;
  

}
.header-icon {
  font-size: 18px;
}
.searchtype{
  width:90px;
}
.pointer{
  cursor: pointer;
}
.searchinput {
  width:80px;
}
.header-btn {
  
  font-size:16px;
}
/deep/ .header-btn .v-btn__content{
  opacity: 1 !important;
}
/deep/ .v-list-item--active {
  color:black !important;
  background-color:white !important;
}
</style>

<style lang="sass">
  @import '~vuetify/src/styles/styles.sass'

  @media #{map-get($display-breakpoints, 'md-and-up')}
    .smfixed 
      z-index:9 !important
  
  @media #{map-get($display-breakpoints, 'sm-and-down')}
    .vlogo 
      width: 120px
    .mainbody 
      top: 79px
  
    
</style>