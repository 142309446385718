const state = {
    domain: "",
    imgdomain : "",
    apidomain:"",
    advinfo : {HOME_TOPIC:{adv_image:""}},
    home_banner : [],
    home_cate : [],
    home_point : [],
    topic_image : "",
    goods_banner : "",
    profiles_banner : "",
    showlogin : false,
    brand_banner : {}
};

const mutations = {
    SET_DOMAIN: (state, domain) => {
        state.domain = domain;  
    },
    SET_SHOWLOGIN: (state, domain) => {
        state.showlogin = domain;  
    },
    SET_IMGDOMAIN: (state, domain) => {
        state.imgdomain = domain;  
    },
    SET_APIDOMAIN: (state, domain) => {
        state.apidomain = domain;  
    },
    SET_ADVINFO: (state, advinfo) => {
        state.advinfo = advinfo;  
    },
    SET_TOPIC_IMAGE: (state, domain) => {
        
        state.topic_image = domain;  
    },
    SET_GOODS_BANNER: (state, domain) => {
        
        state.goods_banner = domain;  
    },
    SET_PROFILES_BANNER: (state, domain) => {
        
        state.profiles_banner = domain;  
    },
    SET_BRAND_BANNER: (state, domain) => {
        
        state.brand_banner = domain;  
    },
    SET_HOME_CATE: (state, domain) => {
        state.home_cate = domain;  
    },
    SET_HOME_BANNER: (state, domain) => {
        state.home_banner = domain;  
    },
    SET_HOME_POINT: (state, domain) => {
        state.home_point = domain;  
    },
}

const actions = {
    domain({ commit }, url) {
        commit("SET_DOMAIN", url);
    },
    imgdomain({ commit }, url) {
        commit("SET_IMGDOMAIN", url);
    },
    apidomain({ commit }, url) {
        commit("SET_APIDOMAIN", url);
    },
    advinfo({ commit }, info) {
        commit("SET_ADVINFO", info);
    },
    topic_image({ commit }, info) {
        commit("SET_TOPIC_IMAGE", info);
    },
    home_cate({ commit }, info) {
        commit("SET_HOME_CATE", info);
    },
    home_banner({ commit }, info) {
        commit("SET_HOME_BANNER", info);
    },
    home_point({ commit }, info) {
        commit("SET_HOME_POINT", info);
    },
    goods_banner({ commit }, info) {
        commit("SET_GOODS_BANNER", info);
    },
    profiles_banner({ commit }, info) {
        commit("SET_PROFILES_BANNER", info);
    },
    brand_banner({ commit }, info) {
        commit("SET_BRAND_BANNER", info);
    },
    showlogin({ commit }, info) {
        commit("SET_SHOWLOGIN", info);
    },
}

export default {
    namespaced: true,
    state,
    actions,
    mutations,
};