
import request from './request';


export default new function API() {

  this.login = params => request.post({action:"login", ...params});
  this.logout = () => request.post({action:"logout"});
  this.userinfo = () => request.get({action:"userinfo"});
  this.register = params => request.post({action:"register", ...params});
  this.mobilecode = params => request.post({action:"mobcode", ...params});
  this.addaddress = params => request.post({action:"addaddress", ...params});
  this.getaddresses = () => request.post({action:"getaddresses"});
  this.getarea = () => request.post({action:"getarea"});

  this.brandlist = () => request.post({action:"brandlist"});
  this.branddetail = (params) => request.post({action:"branddetail", ...params});
  this.updatePassword = params => request.post({action:"updatepwd", ...params});
  this.resetpwd = params => request.post({action:"resetpwd", ...params});

  this.login3pcall = params => request.post({action:"login3pcall", ...params});
  this.bind = params => request.post({action:"bind", ...params});
  this.wxconfig = params => request.get({action:"wxconfig", ...params});
  

  this.getwithdrawaccount = params => request.get({action:"getwithdrawaccount", ...params});
  this.addwithdrawaccount = params => request.post({action:"addwithdrawaccount", ...params});
  this.withdraw = params => request.post({action:"withdraw", ...params});
  this.coupon = params => request.get({action:"coupon", ...params});
  
  this.commission = params => request.post({action:"commission", ...params});
  
}();
