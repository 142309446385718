import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import '@/styles/index.scss';
import '@/assets/fonts/fonts.css';
import router from './router';

import VueGtag from "vue-gtag";


import store from './store';
import API from './api';
import * as consts from './utils/consts';
import * as utils from './utils/util';
import "./assets/iconfont"
import message from 'vuetify-message-snackbar';
import { VSnackbar, VBtn, VIcon } from "vuetify/lib";
import VueClipboard from 'vue-clipboard2'

VueClipboard.config.autoSetContainer = true
Vue.use(VueClipboard)

Vue.config.productionTip = false

Vue.router = router;
Vue.store = store;

Vue.prototype.$api = API;
Vue.prototype.$consts = consts;
Vue.prototype.$util = utils;

Vue.use(VueGtag, {
  config: { id: "G-MSJZW7XYLF" }
});

Vue.component("v-snackbar", VSnackbar);
Vue.component("v-btn", VBtn);
Vue.component("v-icon", VIcon);

const vuetifyPreset = {};
Vue.use(message, {
  //autoRemove: true,
	closeButtonContent: '关闭',
	offsetTop: 10,
	autoTransitionSetting: true,
  timeout:3000,
  vuetifyPreset,
  //absolute: true,
	class: 'margin-top-animation',
  color:'shades',
  light:false,
	// 预设icon
	presetIcon: {
		success: 'mdi-checkbox-marked-circle'
	},
  vuetifyInstance: vuetify,

});



new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
