
import request from './request';


export default new function API() {

  
  this.goodsdetail = params => request.get({action:"goodsd", ...params});
  this.goodslist = params => request.get({action:"goodsl", ...params});
  this.catelist = () => request.get({action:"catel"});
  this.filterdict = (cateid) => request.get({action:"filterdict",cate:cateid});
  this.addcart = params => request.post({action:"addcart", ...params});
  this.cartlist = () => request.get({action:"cartlist"});
  
  
  this.addfavorite = params => request.post({action:"addfavorite", ...params});
  this.removefavorite = params => request.post({action:"removefavorite", ...params});
  this.favorite = () => request.get({action:"favorite"});
  this.likes = params => request.get({action:"likes", ...params});

  this.removecart  = params => request.post({action:"removecart", ...params});

  this.poster = params => request.post({action:"poster", ...params});

}();
