import Vue from 'vue';
import Axios from 'axios';


Vue.prototype.$http = Axios;

const service = Vue.prototype.$http.create({
  headers: {
    'content-type': Vue.prototype.contentType || 'application/json;charset=utf-8',
  },
});



/* eslint-disable no-param-reassign */
service.interceptors.request.use(
  config => {
    // 用户Token传递给后端
    const token = Vue.prototype.$util.getToken()
    if (token !== null) {
      config.headers['X-Token'] = token
      //console.log(3)
    }

    // Cookie
    // const cookie = CONFIG.cookie
    // if (cookie !== null) {
    //   config.headers['Cookie'] = cookie
    // }

    // post请求序列化参数
    /*if (
      
      config.method === 'post' &&
      config.headers['Content-Type'] !== 'multipart/form-data'
    ) {
      config.data = qs.stringify(config.data, {arrayFormat:"repeat"})
    }*/
    return config
  },
  error => {
    // console.log('错误的传参')
    return Promise.reject(error)
  }
)

service.interceptors.response.use(response => response, (error) => {
  console.log('http error', error);
  return Promise.reject(error.status ? error : error.response);
});

//let loginNotice = null 
function access(param, method) {

  let url = process.env.VUE_APP_DOMAIN + "api"
  param = param || {};
  // if (window.location.search.indexOf('debug') > -1) {
  //   param.debug = true;
  // }

  let ret = null;
  const upperMethod = method.toUpperCase();

  /* eslint-disable no-underscore-dangle */
  const __randNum = Math.random();

  if (upperMethod === 'POST') {
    let extparams = { }
    if (param.action) extparams.action=param.action
    extparams.__randNum = __randNum
    ret = service.post(url, param, { params: extparams });
  } else if (upperMethod === 'PUT') {
    ret = service.put(url, param, { params: { __randNum } });
  } else if (upperMethod === 'DELETE') {
    ret = service.delete(url, { params: { ...param, __randNum } });
  } else {
    ret = service.get(url, { params: { ...param, __randNum } });
  }

   
  return ret.then((res) => {
    // Note:
    // When successful, the body data is returned;
    // when it fails, it returns res,
    // in order to ensure the same as the return value of the http request error.

    if (res.data.code==401) {

      /*if (Vue.prototype.$util.inWeiXin() && Vue.prototype.$util.isIPhone())
      {
        
      } else {
        Vue.store.dispatch("app/showlogin",true)
      }*/
      Vue.store.dispatch("app/showlogin",true)
      
      
      
      //Vue.store.dispatch("user/set_userinfo",{user_name:false,cart_count:0})
      //Vue.router.push("/")
      //this.$message.warning('请先登录商城')
      //return Promise.reject(res);
      return res.data;
    }
    return res.data;
    //return Promise.reject(res);
  }, (res) => {
    // FIXME: This is not the best method.
    let errMsg = '';

    if (res.status === 401) {
      errMsg = '您无权访问该页面';
    } else if (res.status === 403) {
      errMsg = '禁止访问';
    } else if (res.status === 404) {
      errMsg = '您访问的页面不存在了';
    } else if (res.status === 500) {
      errMsg = '服务器出了一点问题，请联系管理员';
    }
    console.log(errMsg)


    // Throw it again so you can handle it later.
    return Promise.reject(res);
  });
}

export default {
  get(param) {
    return access(param, 'get');
  },
  delete(param) {
    return access(param, 'delete');
  },
  post(param) {
    return access(param, 'post');
  },
  put(param) {
    return access(param, 'put');
  },
};
