import Vue from 'vue';
import Vuex from 'vuex';
import app from './modules/app';
import user from './modules/user';
import order from './modules/order';

import getters from './getters';

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== 'production';
export default new Vuex.Store({
  modules: {
    app,
    user,
    order
  },
  app,
  user,
  order,
  getters,
  strict: debug,
});
